<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10569')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="box">
					<!-- [D] 리뷰들 -->
					<div class="list__meta" style="padding-bottom:0" v-if="hotel.length > 0">
						<div class="list__check">
							<div class="checkbox">
								<label>
									<input type="checkbox" class="chk blind" v-model="photoOnly">
									<span class="checkbox__bg"></span>
									<span class="grey2">{{t('10571')}}</span>
								</label>
							</div>
						</div>
						<div>
							<div class="list__grad info-grad">
								<span class="list__icon info-icon">
									<i class="icon icon-star"></i>
								</span>
								<span class="list__gradnum info-gradnum"> {{hotel[0].SCORE.toFixed(1)}} </span>
								<span class="list__gradcounter info-gradcounter"> ({{hotel[0].REVIEWS.toLocaleString("en-US")}}) </span>
							</div>
						</div>
					</div>
					<div class="myreview">
						<!-- 1 -->
						<div class="review__item" v-for="review in reviews" :key="review.BOOKING_NO">
							<div class="review__body">
								<div class="flex">
									<div class="review__type">
										{{review.PROD_NM}} - {{review.PROD_TYPE}}
									</div>
									<div class="review__name">
										{{review.REVIEW_NICKNM}}
									</div>
								</div>
								<div class="flex">
									<div class="review__grad">
										<i v-for="i in 5" :key="i" class="icon" :class="i <= review.SCORE ? 'icon-star' : 'icon-star--light'"></i>
									</div>
									<div class="review__date">
										{{review.YMD}}
									</div>
								</div>
								<div class="review__slider">
									<div class="carousel">
										<!-- [REST] 지역별 숙소 호출 -->
										<div class="carousel__slider" data-slider="carousel">
											<swiper :slides-per-view="1.1" :space-between="16">
												<swiper-slide v-for="image in review.images" :key="image.SUB_SEQ">
													<div class="carousel__image">
														<img :src="image.IMG_URL" :alt="image.SUB_SEQ">
													</div>
												</swiper-slide>
											</swiper>
										</div>
									</div>
								</div>
								<div class="review__desc">
									{{review.REVIEW}}
									<button type="button" class="btn-police" @click="handleOpenPolice(review.BOOKING_NO)">
										{{t('10536')}}
									</button>
								</div>
								<div class="review-answer" v-if="review.ANS_TITLE.length > 0">
									<div class="review-answer__title multiline">
										{{review.ANS_TITLE}}
									</div>
									<div class="review-answer__date">
										{{review.ANS_DATE}}
									</div>
									<p class="review-answer__desc multiline">
										{{review.ANS_DESC}}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
	<MessagePolice v-if="openPolice" :handleClose="()=> openPolice=false" :hotelNo="hotelNo" :reviewSeq="reviewSeq" />
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import MessagePolice from "@/components/layers/Message_police"
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	components: {MessagePolice},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const hotelNo = route.params.id;
		const hotel = computed(() => (store.state.mrm02.hotel[hotelNo] || []));
		const photoOnly = ref(false);
		const { t }= useI18n() //번역필수 모듈

		onMounted(() => {
			store.dispatch("mrm02/fetchRoomDetail", { hotelNo });
			store.dispatch("mrm02/fetchMrm0205", { hotelNo });
		});

		const openPolice = ref(false);
		const reviewSeq = ref(0);

		const handleOpenPolice = (seq) => {
			reviewSeq.value = seq;
			openPolice.value = true;
		}

		return {
			hotel,
			reviews: computed(() => ((store.state.mrm02.mrm0205[hotelNo] || [])
				.filter(review => photoOnly.value === true ? review.images.length > 0 : true)
				.map(review => ({
					...review,
					YMD: dayjs(review.YMD).format("YYYY.MM.DD"),
				}))
			)),
			photoOnly,
			goBack: () => router.back(),
			openPolice,
			reviewSeq,
			handleOpenPolice,
			t,
			i18n
		}

	}
}
</script>